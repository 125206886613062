import request from "@/utils/request";


// 职员列表
export function getStaffList(data) {
  return request({
    url: '/wxapp/merClerk/list',
    method: 'get',
    data
  })
}

// 职员详情
export function getStaffDetail(id) {
  return request({
    url: `/wxapp/merClerk/detail?id=${id}`,
    method: 'get'
  })
}

// 职员编辑
export function saveStaffPage(url, data) {
  return request({
    url: url,
    method: 'post',
    data
  })
}